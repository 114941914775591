var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "n-page",
    [
      _c(
        "a-row",
        { attrs: { type: "flex" } },
        [
          _vm.user && _vm.user.user
            ? _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-card",
                    {
                      staticClass: "gx-card-table-full",
                      attrs: { title: "Team Membership" },
                    },
                    [
                      _c("my-teams-table", {
                        ref: "member_team_listing",
                        attrs: { "member-id": parseInt(_vm.user.user.id) },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }