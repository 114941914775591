var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("a-table", {
    staticClass: "gx-table-responsive",
    attrs: { columns: _vm.columns, "data-source": _vm.schedule },
    scopedSlots: _vm._u([
      {
        key: "status",
        fn: function (text) {
          return _c(
            "div",
            {},
            [
              text === "accept"
                ? _c(
                    "a-tag",
                    {
                      staticClass: "gx-mb-0",
                      staticStyle: { "border-radius": "30px" },
                      attrs: { color: "#27ae60" },
                    },
                    [_vm._v("Active")]
                  )
                : _vm._e(),
              text === "invite"
                ? _c(
                    "a-tag",
                    {
                      staticClass: "gx-mb-0",
                      staticStyle: { "border-radius": "30px" },
                      attrs: { color: "#f39c12" },
                    },
                    [_vm._v("Invite Pending")]
                  )
                : _vm._e(),
              text === "request"
                ? _c(
                    "a-tag",
                    {
                      staticClass: "gx-mb-0",
                      staticStyle: { "border-radius": "30px" },
                      attrs: { color: "#d35400" },
                    },
                    [_vm._v("Pending Club Acceptance")]
                  )
                : _vm._e(),
              text === "archive"
                ? _c(
                    "a-tag",
                    {
                      staticClass: "gx-mb-0",
                      staticStyle: { "border-radius": "30px" },
                      attrs: { color: "#d35400" },
                    },
                    [_vm._v("No Longer a Member")]
                  )
                : _vm._e(),
              text === "decline"
                ? _c(
                    "a-tag",
                    {
                      staticClass: "gx-mb-0",
                      staticStyle: { "border-radius": "30px" },
                      attrs: { color: "#c0392b" },
                    },
                    [_vm._v("Declined Invitation")]
                  )
                : _vm._e(),
              text === "cancel"
                ? _c(
                    "a-tag",
                    {
                      staticClass: "gx-mb-0",
                      staticStyle: { "border-radius": "30px" },
                      attrs: { color: "#c0392b" },
                    },
                    [_vm._v("Cancelled Request")]
                  )
                : _vm._e(),
            ],
            1
          )
        },
      },
      {
        key: "handlers",
        fn: function (text, record) {
          return _c(
            "div",
            {},
            [
              record.status === "request"
                ? _c(
                    "a-button",
                    {
                      staticStyle: {
                        "margin-bottom": "0",
                        "margin-left": "5px",
                      },
                      attrs: { size: "small", type: "danger" },
                      on: {
                        click: function ($event) {
                          return _vm.userTableList(record.team_id, "archive")
                        },
                      },
                    },
                    [_vm._v("\n      Cancel Join Request\n    ")]
                  )
                : _vm._e(),
              record.status === "accept"
                ? _c(
                    "a-button",
                    {
                      staticStyle: {
                        "margin-bottom": "0",
                        "margin-left": "5px",
                      },
                      attrs: { size: "small", type: "danger" },
                      on: {
                        click: function ($event) {
                          return _vm.teamSchedule(record.team_id)
                        },
                      },
                    },
                    [_vm._v("\n      View\n    ")]
                  )
                : _vm._e(),
              record.status === "invite"
                ? _c(
                    "a-button",
                    {
                      staticClass: "gx-btn-cyan",
                      staticStyle: {
                        "margin-bottom": "0",
                        "margin-left": "5px",
                      },
                      attrs: { size: "small", type: "danger" },
                      on: {
                        click: function ($event) {
                          return _vm.userTableList(record.team_id, "accept")
                        },
                      },
                    },
                    [_vm._v("\n      Accept Invite\n    ")]
                  )
                : _vm._e(),
              record.status === "invite"
                ? _c(
                    "a-button",
                    {
                      staticClass: "gx-btn-red",
                      staticStyle: {
                        "margin-bottom": "0",
                        "margin-left": "5px",
                      },
                      attrs: { size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.userTableList(record.team_id, "decline")
                        },
                      },
                    },
                    [_vm._v("\n      Decline Invite\n    ")]
                  )
                : _vm._e(),
            ],
            1
          )
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }